download-widget {
  .minimized {
    background-color: var(--colorPrimary, @defaultColorPrimary);
    color: white;
    position: fixed;
    bottom: 15px;
    right: 30px;
    z-index: 999;
  }

  .maximized {
    position: fixed;
    bottom: 0;
    right: 15px;
    width: 25%;
    max-height: 50%;
    overflow-y: auto;
    z-index: 999
  }

  .fa-window-minimize {
    font-size: 0.75em
  }
}
