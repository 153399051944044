.report {
  blockquote {
    padding: 5px 10px;
  }

  table {
    margin-bottom: 10px;
  }

  .table > tbody + tbody {
    border-top: none;
  }

  [data-section-id] {
    scroll-margin-top: 60px;
  }
}

.report-thead {
  color: white;
  background-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg) !important;

  tr > th {
    overflow: hidden;
    white-space: normal !important;
    word-wrap: break-word;

    &.fixed-width {
      max-width: 75px;
    }
  }

  + tbody {
    > tr > td {
      span > p {
        display: inline;
      }
    }
  }
}

.meta-data {
  font-size: 14px;
  border: solid lightgrey 0.1px;
}

.options-title {
  max-width: 200px;
}

.condensed-report-select-question-dropdown {
  min-height: 250px;
}

.condensed-report-select-question-option {
  padding-top: 8px;
  padding-bottom: 8px;
}

.progress {
  > .progress-bar-text {
    position: absolute;
    right: 17px;
    top: 3px;
    font-size: 11px;
    background-color: #f5f5f5;
    color: black;
    border-radius: 12px;
    padding: 0 2px;
    opacity: 0.8;
  }
}
