.uib-datepicker-popup.dropdown-menu {
	padding: 0;

	> li > .uib-datepicker {
		> table {
			> thead {
				padding-bottom: 10px;
				background: lighten(@theme-blue-gray, 5%);
				> tr > th {
					> button {
						border: none;
						background: none;
					}
					> small {
						display: inline-block;
						padding: 10px 0;
					}
				}
			}
			&.uib-daypicker {
				> tbody > tr > td > button.btn {
					width: 35px;
					height: 35px;
					border-radius: 50%;
				}
			}
			> tbody > tr > td {
				padding: 2px;
				> button.btn {
					border: none;
					&.active {
						background: @brand-primary;
						color: white;
						&:hover {
							background: @brand-primary;
						}
					}
					&:hover {
						background: @theme-blue-gray;
					}
					> span {
						&.text-muted {
							color: @theme-grey-3;
						}
					}
				}
				> em {
					color:@theme-blue-gray;
					font-size: 75%;
				}
			}
		}
	}

	.uib-button-bar  {
		padding: 0;
		background: lighten(@theme-blue-gray, 5%);
		.btn-group {
			float:none !important;
		}

		button, .btn-group > button {
			background: none;
			box-shadow: none;
			color:@text-color;
			text-shadow: none;
		}
	}
}