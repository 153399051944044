/**
 * For the correct positioning of the placeholder element, the dnd-list and
 * it's children must have position: relative
 */
ul[dnd-list],
ul[dnd-list] > li {
  position: relative;
}

ul[dnd-list].ul-hover > li:hover {
	background-color: darken(@background-color, 2%);
}

/**
 * The dnd-list should always have a min-height,
 * otherwise you can't drop into it once it's empty
 */
[dnd-list] {
  min-height: 42px;
  padding-left: 0px;
}

/**
 * An element with .dndPlaceholder class will be
 * added to the dnd-list while the user is dragging
 * over it.
 */
ul[dnd-list],[dnd-list] {
	margin: 0;
	li.dndPlaceholder {
		display: block;
		background-color: @theme-blue-2;
	    border: 2px dashed @theme-blue-3;
	    margin: 0px;
		min-height: 42px;
		list-style: none;
	}
}

/**
 * The dnd-lists's child elements currently MUST have
 * position: relative. Otherwise we can not determine
 * whether the mouse pointer is in the upper or lower
 * half of the element we are dragging over. In other
 * browsers we can use event.offsetY for this.
 */
[dnd-list] {
	li {
	  border: 1px solid #ddd;
	  border-left-color: transparent;
	  border-right-color: transparent;
	  display: block;
	  padding: 10px 15px;
	  margin-bottom: -1px;
	  &:hover {
		cursor: pointer;
	  }
	  &:first-of-type {
			border-top-color: transparent;
      }
	  &:last-of-type {
			border-bottom-color: transparent;
			border-bottom-left-radius: 2px;
			border-bottom-right-radius: 2px;
		}
	  &.dndPlaceholder {
	  	background: @gray-lighter;
	  	border: 3px dashed @gray-light;
	  }
	}
}

/**
 * Show selected elements in blue
 */
[dnd-list] .selected {
  background-color: @theme-blue-gray;
  color: @theme-blue-4;
}

.report-label-li {
	border: none !important;
	border-left-color: inherit !important;
	border-right-color: inherit !important;
	padding: 0 !important;
	margin-bottom: 0 !important;
}

[dnd-list] .dndDraggingSource {
	display: none;
}
