.list .disabled {
    text-decoration: line-through;
}

.table-responsive {
    border: none !important;
}

table .label {
    display: inline-block;
}

.table {
    &--layout-fixed {
      table-layout: fixed;
    }

    &--overflow-wrap {
        > tbody > tr > td {
            word-wrap: break-word;
            overflow-wrap: break-word;
        }
    }

    &.table-valign-middle {
        > tbody > tr > td {
            vertical-align: middle;
        }
    }

    .table-nested, .table-nested .table {
      background: lighten(@brand-primary, 47%);

      .table-nested {
        th {
          border-top: none;
        }
        th, td {
          border-color: white;

          &:first-of-type {
            border:none;
          }
        }
      }
    }

    .table-fit-columns {
        > td {
            white-space: nowrap;
            &:last-of-type {
                width: 100%;
            }
        }
    }

    .table-fit-first-column {
        > td {
            &:first-of-type {
                white-space: nowrap;
            }
            &:last-of-type {
                width: 100%;
            }
        }
    }

    // Condensed table w/ half padding

    &.table-ultra-condensed {
      > thead,
      > tbody,
      > tfoot {
        > tr {
          > th,
          > td {
            padding: 2px;
          }
        }
      }
    }

    [empty-table] {
        overflow: hidden;
    }
    .empty-table-container {
        position: relative;
        padding: 1rem;
        text-align: center;
        > i {
            font-size: 3.125rem;
        }
        > span {
            display: block;
            padding-top: 1rem;
            font-size: @fontSizeXL;
        }
    }

    tbody tr.disabled {
        text-decoration: line-through;
        background-color: @brand-danger;
        color: white;
    }

    tr {
        th {
            &.th-sm {
                width: 70px;
            }
        }
    }

    thead {
        > tr {
            th {
                white-space: nowrap;
                span:first-of-type {
                    max-width: calc(100% - 24px);
                    padding-right: 7px;
                }
                span.sortable-table-icon:last-of-type {
                    background: white;
                    box-shadow: -3px 0 5px white;
                }
            }
        }
    }

    tbody {
        > tr {
            > td {
                &.table-fit-column {
                    white-space: nowrap;
                }
                .progress {
                    margin: 0;
                }
                &.truncate-container {
                    max-width: 100px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 100%;
                }
            }
        }
    }

    &.table-stacked {
        @media screen and (max-width: @screen-xs-max) {
            tr, td {
                display:block;
                .mobile-header {
                    display: block;
                    color:@brand-success;
                    font-weight: @fontWeightStrong;
                }
            }

            thead {
                display: none;
            }
        }
    }

    &.table-hover {
        > tbody > tr:hover {
            transition: .125s all ease-in;
        }
    }
}

dice-person-avatar > span > a > img {
    width: 35px;
    height: 35px;
    -webkit-border-radius: 99em;
    -moz-border-radius: 99em;
    border-radius: 99em;
    border: 1px solid #eee;
    box-shadow: 0 3px 2px rgba(0, 0, 0, 0.3);
}
