.label-outline-mixin(@border, @color) {
  background: none;
  border-color: @border;
  border-width: 1px;
  border-style: solid;
  color: @color;
  text-shadow: none;
}

.label {
  border-radius: 30px;

  &.label-xs {
    font-size: 50%;
    vertical-align: middle;
  }

  &.label-uppercase {
    text-transform: uppercase;
  }

  &.label-primary {
    border: 1px solid @brand-primary;
  }

  &.label-success {
    border: 1px solid @brand-success;
  }

  &.label-warning {
    border: 1px solid @brand-warning;
  }

  &.label-danger {
    border: 1px solid @brand-danger;
  }

  &.label-info {
    border: 1px solid @brand-info;
  }

  &.label-default {
    border: 1px solid @gray-light;
  }

  &.label-outline {
    &.label-primary {
      .label-outline-mixin(@brand-primary, @brand-primary);
    }

    &.label-success {
      .label-outline-mixin(@brand-success, @brand-success);
    }

    &.label-warning {
      .label-outline-mixin(@brand-warning, @brand-warning);
    }

    &.label-danger {
      .label-outline-mixin(@brand-danger, @brand-danger);
    }

    &.label-info {
      .label-outline-mixin(@brand-info, @brand-info);
    }

    &.label-default {
      .label-outline-mixin(darken(@theme-blue-gray, 5%), darken(@theme-blue-gray, 7.5%));
    }
  }
}
