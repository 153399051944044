.badge {
	&.badge-success {
		background: @brand-success !important;
	}
	&.badge-danger {
		background: @brand-danger !important;
	}
	&.badge-warning {
		background: @brand-warning !important;
	}
	&.badge-info {
		background: @brand-info !important;
	}
	&.badge-primary {
		background: @brand-primary !important;
	}
	&.badge-muted {
		opacity: 0.5;
	}
}
