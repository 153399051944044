@media print {
  @page {
    margin-top: 7mm;
    margin-right: 0mm;
    margin-bottom: 7mm;
    margin-left: 0mm;

    size: A4;
  }
  * {
    -webkit-print-color-adjust: exact;
  }
  body {
    background-color: white !important;

    tr {
      i:not(.fa-asterisk) {
        display: none !important;
      }
    }
  }

  @media screen and (max-width: 767px) {
    .visible-print.hidden-xs {
      display: table !important;
    }
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    .visible-print.hidden-sm {
      display: table !important;
    }
  }

  .no-print {
    display: none !important;
  }

  .no-print-border {
    border: none !important;
    box-shadow: none;
  }

  .no-print-padding {
    padding: 0 !important;
  }

  .print-new-page {
    page-break-after: always !important;
  }

  .customer-container {
    .customer-header {
      position: absolute;
      top: 0;
      right: 15px;
      width: 100%;

      #customer-logo {
        background-position: center right;
      }
    }

    .customer-body {
      margin-top: 20px;
    }
  }

  dice-person-avatar > span > a > img {
    box-shadow: none !important;
  }

  .report-print {
    .progress {
      line-height: 16px;
      height: 16px;
      border: none;
      box-shadow: none;

      .progress-bar {
        font-weight: @fontWeightStrong;
        line-height: 16px;
        height: 16px;
        box-shadow: none;
      }
    }

    .does-not-uses-default-options {
      .progress-bar {
        + span {
          line-height: 16px;
        }
      }
    }

    .uses-default-options {
      .options-title {
        strong {
          line-height: 16px;
          display: block;
          white-space: nowrap;
        }
      }
    }

    blockquote {
      margin-bottom: 5px;
      padding: 5px 10px;

      border-left-width: 2px;

      > footer {
        margin-bottom: 5px;
      }
    }

    p {
      margin: 0 0 5px;
    }
  }

  .nowrap {
    white-space: nowrap;
  }
}

/**
 *  Bootstrap print 3.x-1.2 for Bootstrap v3.
 *
 *  Mange grids using Bootstrap v3 for printed media.
 *  This will help controlling grid columns size on printed pages.
 *  https://github.com/Natshah/bootstrap-print
 *
 */

.make-print-grid-columns() {
  // Common styles for all sizes of grid columns, widths 1-12
  .col(@index) {
    // initial
    @item: ~'.col-p-@{index}';
    .col((@index + 1), @item);
  }
  .col(@index, @list) when(@index =< @grid-columns) {
    // general; "=<" isn't a typo
    @item: ~'.col-p-@{index}';
    .col((@index + 1), ~"@{list}, @{item}");
  }
  .col(@index, @list) when(@index > @grid-columns) {
    // terminal
    @{list} {
      float: left;
      position: relative;
      // Prevent columns from collapsing when empty
      min-height: 1px;
      // Inner gutter via padding
      padding-left: ceil((@grid-gutter-width / 2));
      padding-right: floor((@grid-gutter-width / 2));
    }
  }
  .col(1); // kickstart it
}

// Create grid for specific class
.make-print-grid() {
  .make-print-grid-columns();
  .loop-grid-columns(@grid-columns, p, width);
  .loop-grid-columns(@grid-columns, p, pull);
  .loop-grid-columns(@grid-columns, p, push);
  .loop-grid-columns(@grid-columns, p, offset);
}

@media print {
  //  We need to import variables and mixins first.
  // @import '../bootstrap/less/variables';
  // @import '../bootstrap/less/mixins/grid-framework';
  // @import '../bootstrap/less/mixins/grid';

  // Make print grid to work with the bootstrap variables.
  .make-print-grid();

  /* Always insert a page break after the element */
  .always-page-break-after,
  .always-pba {
    page-break-after: always;
  }

  /* Avoid page break after the element (if possible) */
  .avoid-page-break-after,
  .avoid-pba {
    page-break-after: avoid;
  }

  /* Insert page breaks after the element so that the next page is formatted as a left page */
  .left-page-break-after,
  .left-pba {
    page-break-after: left;
  }

  /* Insert page breaks after the element so that the next page is formatted as a right page */
  .right-page-break-after,
  .right-pba {
    page-break-after: right;
  }

  /* Always insert a page break before the element */
  .always-page-break-before,
  .always-pbb {
    page-break-before: always;
  }

  /* Avoid page break before the element (if possible) */
  .avoid-page-break-before,
  .avoid-pbb {
    page-break-before: avoid;
  }

  /* Insert page breaks before the element so that the next page is formatted as a left page */
  .left-page-break-before,
  .left-pbb {
    page-break-before: left;
  }

  /* Insert page breaks before the element so that the next page is formatted as a right page */
  .right-page-break-before,
  .right-pbb {
    page-break-before: right;
  }

  /* Avoid page break inside the element (if possible) */
  .avoid-page-break-inside,
  .avoid-pbi {
    page-break-inside: avoid;
  }
}
