#alerts {
  position: fixed;
  z-index: 9999;
  @media (min-width: @screen-sm-min) {
    top: 70px;
    left: 10%;
    width: 90%;
    transform: translate(-5%);
  }
  @media (min-width: @screen-md-min) {
    width: 50%;
    left: 50%;
    transform: translate(-50%);
  }
  @media (max-width: @screen-xs-max) {
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    transform: translate(0);
    .alert {
      margin-bottom: 0;
    }
  }
}

.ribbon {
  z-index: 9000;
  position: fixed;
  top: 15px;
  width: 310px;
  padding: 1px 0;
  background: #c00;
  color: #eee;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  right: -127px;
  transform: rotate(40deg);
  opacity: 0.9;
  > span {
    display: block;
    padding: 1px 0;
    height: 24px;
    line-height: 24px;
    color: inherit;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;
    border: 1px solid rgba(255, 255, 255, 0.3);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.31);
  }
  &:hover {
    opacity: 0.5;
  }
}
