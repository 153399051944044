.text-superscript {
  vertical-align: super;
  font-size: 50%;
  margin-left: -3px;
}

.text-condensed {
  p {
    margin: 0;
  }
}

.text-strikethrough {
  text-decoration: line-through;
}

.text-muted {
  opacity: 0.5;
}

html {
    position: relative;
    min-height: 100%;
}
html,body{
    -webkit-overflow-scrolling : touch !important;
}

body {
	padding:0;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
	color: @colorTextBase;
  background-color: #fafcfe;
  position: relative;

  &.modal-open, &.loader-active {
    .site-wrapper {
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-backface-visibility: hidden;
      backface-visibility: hidden;
      filter: blur(4px);
      -webkit-filter: blur(4px);
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
  	}
  }
}

.panel-heading .p-abs {
	top: 4px;
	right: 4px;
}

.question-label {
  font-weight: @fontWeightStrong;
}

.block-questions-accordion{
  .panel-heading {
    height: 42px;
  }

  .panel-title {
    font-size: 15px;
  }
}

::-ms-clear {
  display: none;
}

::-ms-reveal {
  display: none;
}

.about-menu {
  width: 200px;
  padding: 5px;
  margin: 0px;
}

.substitute-badge {
  position: relative;
  bottom: 1px;
}

.border {
  border: 1px solid @theme-grey-7;
}

.typeahead {
  &.align-right {
    .dropdown-menu {
      left: auto !important;
      right: 0 !important;
    }
  }
  .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;

    .active > a, .active > a:hover  {
      background-color: #00bbff !important;
    }
    a {
      margin-bottom: 0 !important;
    }
  }
}

dl.dl-horizontal {
  &.dl-small {
    dt {
      width: 90px;
    }
    dd {
      margin-left: 100px;
    }
  }
}

.link {
  &:hover {
    text-decoration: underline;
  }
}

a.link-button {
  display: inline-block;
  font-size: 85%;
  font-weight: @fontWeightStrong;
  line-height: 1.6;
  color: white;
  background-color: var(--colorPrimary, @defaultColorPrimary);
  padding: 0 5px;
  margin: 0 0 2px 1px;

  -webkit-border-top-right-radius: 0.25em;
  -webkit-border-bottom-right-radius: 0.25em;
  -webkit-border-bottom-left-radius: 0.25em;
  -webkit-border-top-left-radius: 0.25em;
  -moz-border-radius-topright: 0.25em;
  -moz-border-radius-bottomright: 0.25em;
  -moz-border-radius-bottomleft: 0.25em;
  -moz-border-radius-topleft: 0.25em;
  border-top-right-radius: 0.25em;
  border-bottom-right-radius: 0.25em;
  border-bottom-left-radius: 0.25em;
  border-top-left-radius: 0.25em;

  > small,
  > strong {
      font-size: 80%;
      font-weight: @fontWeightStrong;
  }

  &:hover {
    text-decoration: none;
  }
}

.tag {
  line-height: 1.8;
  padding: 0px 5px;
  border: 1px solid @theme-grey-5;
  background-color: lighten(@theme-grey-7, 1%);
  margin: 0px 5px 2px 0px;
  display: inline-block;

  > a {
    color: @gray;
    &:hover {
      color: @gray-light;
    }
  }
}

.admin h2 {
  margin-top: 5px;
  padding-bottom: 2px;
  border-bottom: 1px solid #ccc;
  font-size: 18px;
}

.text-break {
  word-wrap: break-word;
  overflow-wrap: break-word;
}

a.accordion-toggle {
  &:focus {
      outline: none;
      text-decoration: none;
  }
}

.product-selector {
  margin-top: 13px;
  padding-right: 10px;

  .product-select--wrapper .product-select .product-select-button h5 {
    color: white
  }
}
