.rounded(@radius: 2px) {
    -webkit-border-radius: @radius;
    -moz-border-radius: @radius;
    border-radius: @radius;
}
.opacity(@opacity: 0.5) {
    -moz-opacity: @opacity;
    -khtml-opacity: @opacity;
    -webkit-opacity: @opacity;
    opacity: @opacity;
    @opperc: (@opacity * 100);
    -ms-filter: ~"progid:DXImageTransform.Microsoft.Alpha(opacity=@{opperc})";
    filter: ~"alpha(opacity=@{opperc})";
}
.transform(...) {
    -webkit-transform: @arguments;
    -moz-transform: @arguments;
    -o-transform: @arguments;
    -ms-transform: @arguments;
    transform: @arguments;
}
.transition(@duration: 0.2s, @ease: ease-out) {
    -webkit-transition: all @duration @ease;
    -moz-transition: all @duration @ease;
    -o-transition: all @duration @ease;
    transition: all @duration @ease;
}