.drop-box-container {
    display: table;
}

.drop-box {
    background: #F8F8F8;
    border: 5px dashed #DDD;
    text-align: center;
    display: table-cell;
    vertical-align: middle;
    padding: 25px;
    margin: 10px;
}

.dragover {
    border: 5px dashed blue;
}
