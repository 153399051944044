.btn-outline-mixin(@border, @color) {
  background: none;
  border-color: @border;
  border-width: 1px;
  border-style: solid;
  color: @color;
  text-shadow: none;
  &:hover {
    background: none;
    border-color: darken(@border, 20%);
    color: darken(@border, 20%);
  }
}

.btn.btn-outline {
  &.btn-primary {
    .btn-outline-mixin(@brand-primary, @brand-primary);
  }

  &.btn-success {
    .btn-outline-mixin(@brand-success, @brand-success);
  }

  &.btn-warning {
    .btn-outline-mixin(@brand-warning, @brand-warning);
  }

  &.btn-danger {
    .btn-outline-mixin(@brand-danger, @brand-danger);
  }

  &.btn-info {
    .btn-outline-mixin(@brand-info, @brand-info);
  }

  &.btn-white {
    .btn-outline-mixin(#ccc, white);
  }
}

.btn-rounded {
  border-radius: 30px;
}

.btn {
  transition: background .125s ease-in, border .125s ease-in, color .125s ease-in;

  &.btn-primary, &.btn-success, &.btn-warning, &.btn-danger, &.btn-info, &.btn-white {
    border-color: transparent;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, .125);
  }

  &.btn-white {
    background: white;
    border: 1px solid @theme-blue-gray;
    color: @text-color;
  }

  &.btn-default.btn-customer {
    color: @colorEnvBaseText;
    background-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
    border-color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);

    &:hover {
      background-color: @colorEnvBaseText;
      color: var(--colorEnvBaseBg, @defaultColorEnvBaseBg);
    }
  }

  &.btn-primary {
    background-color: var(--colorPrimary, @defaultColorPrimary);
  }
}

.btn-file {
  position: relative;
  overflow: hidden;
}

.btn-file input[type=file] {
  position: absolute;
  top: 0;
  right: 0;
  min-width: 100%;
  min-height: 100%;
  font-size: 100px;
  text-align: right;
  filter: alpha(opacity=0);
  opacity: 0;
  outline: none;
  background: white;
  cursor: inherit;
  display: block;
}
