.fade-in {
    animation: fadeIn 2s;
    -webkit-animation: fadeIn 2s;
    -moz-animation: fadeIn 2s;
    -o-animation: fadeIn 2s;
    -ms-animation: fadeIn 2s;
}

@keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
  
@-moz-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
  
@-webkit-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
  
@-o-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}
  
@-ms-keyframes fadeIn {
    0% {opacity:0;}
    100% {opacity:1;}
}