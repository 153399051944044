div#timeline {
  background-color: @theme-blue-gray;
  margin-top: 20px;
  margin-bottom: 80px;
  height: 4px;
  position: relative;
  z-index: 0;
  .inside {
    position: absolute;
    height: 4px;
    background-color: #fff;
    width: 0%;
    top: 3px;
    left: 0;
  }
  .dot {
    z-index: 99;
    transition: all 0.3s ease-in-out;
    height: 15px;
    border-radius: 50%;
    position: absolute;
    top: -19px;
    text-align: center;

    .text {
      font-size: 1.1rem;
      display: block;
      position: relative;
      top: 0px;
      text-align: center;
      transition: all 0.3s ease-in-out;
      .label {
        transition: all 0.3s ease-in-out;
        border: none;
      }
    }

    span {
      display: inline-block;
      margin-top: 8px;
      width: 25px;
      height: 25px;
      background-color: @brand-success;
      border: 3px solid white;
      position: relative;
      border-radius: 50%;
      transition: all 0.3s ease-in-out;
    }
    .text {
      .label {
        background-color: @brand-success;
      }
    }
  }

  .dot-remaining {
    transition: all 0.3s ease-in-out;
    span {
      background-color: @theme-blue-gray;
      border-width: 5px;
    }
    .text {
      .label {
        background-color: @theme-blue-gray;
      }
    }
  }
  .dot-current {
    transition: all 0.3s ease-in-out;
    span {
      background-color: @brand-primary;
    }
    .text {
      .label {
        background-color: @brand-primary;
      }
    }
  }
}

.tooltip {
  .tooltip-inner {
    .status-info-tooltip {
      max-width: 350px;
      width: 125px;
    }
  }
}

@media(max-width: @screen-md-max) {
  status-timeline {
      overflow-x: scroll;
      width: 100%;
      display: block;
      position: relative;
      #timeline {
        width: 1199px;
        margin-bottom: 50px;
      }
  }
}